<template>
	<view class="menu" :class="[isLogo?'menuw':'']">
		<div class="logo">
			<h1 v-if="isLogo" @click="$router.push('/')">
				<p>陵水总工会管理系统</p>
			</h1>
		</div>
		<el-menu router :collapse="isCollapse" active-text-color="#ffffff" background-color="#2f3447"
			class="el-menu-vertical-demo" :default-active="$route.path" text-color="#e0e2ea">
			<el-sub-menu :index="item.id" v-for="(item,index) in menulist" :key="item.id">
				<template #title>
					<el-icon>
						<i :class="'icon iconfont' + ' ' +item.icon"></i>
					</el-icon>
					<span>{{item.name}}</span>
				</template>

				<el-sub-menu :index="items.id" v-show="!items.path" v-for="(items,indexs) in item.children"
					:key="indexs">
					<template #title>{{items.name}}</template>
					<el-menu-item @click="handleselect(itemp.children,itemp.name,itemp.path)" :index="itemp.path"
						v-for="(itemp,indexp) in items.children" :key="itemp.id">{{itemp.name}}</el-menu-item>
				</el-sub-menu>

				<el-menu-item v-show="items.path" v-for="(items,indexs) in item.children" :key="items.id"
					@click="handleselect(items.children,items.name,items.path)" :index="items.path">
					{{items.name}}
				</el-menu-item>
			</el-sub-menu>
		</el-menu>
	</view>
</template>

<script>
	export default {
		data() {
			return {
				isCollapse: false,
				isLogo: true,
				menulist: [],
			}
		},
		computed: {
			menulist() {
				return this.$store.state.menulist
			}
		},
		mounted() {
			var that = this
			this.$EventBus.on('IsCollapse', function() {
				that.isCollapse = !that.isCollapse
				that.isLogo = !that.isLogo
			})
		},
		methods: {
			handleselect(children, title, url) {
				// 获取路由
				let list = this.$store.state.navlist
				// 防止点击添加重复路由
				let leg = list.some(i => i.name == title)
				if (leg == true||url=='/') {
					return
				}
				// 新建路由
				let item = {
					name: title,
					path: url
				}
				// 合并路由
				list.concat(list.push(item))
				// 提交路由
				this.$store.dispatch('GETNAVLIST', list)
			},
			
		}
	}
</script>

<style scoped lang="scss">
	.menuw ::v-deep .el-menu {
		width: 245px !important;
	}

	.el-menu-vertical-demo {
		overflow: hidden;
		height: calc(100vh - 80px);
		overflow-y: auto;
		width: 100%;
	}

	.icon {
		font-size: 18px;
		margin-right: 10px;
		font-weight: 500;
	}

	.logo {
		height: 80px;
		cursor: pointer;
		overflow: hidden;
		text-align: center;
		line-height: 80px;
		color: #ffffff;

		h1 {
			font-size: 22px;
			white-space: nowrap;
		}
	}

	::v-deep .el-menu {
		border: none !important;
	}

	::v-deep .el-sub-menu__title,
	::v-deep .el-menu-item {
		font-size: 13px !important;
		height: 45px;
		line-height: 45px;
	}

	::v-deep .el-menu-item.is-active {
		color: #FFFFFF;
		background: #488bff;
	}
</style>
