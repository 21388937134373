<template>
	<div class="head">
		<i class="icon iconfont icon-daohang" @click="isCollapse"></i>
		<view class="route-nav">
			<span class="line" v-if="crumbname!='正式工会列表'">/</span>
			<span>{{crumbname}}</span>
			<span v-if="sjname">{{sjname}}</span>
			<span class="line" v-if="sjname">/</span>
			<span v-if="qyname">{{qyname}}</span>
		</view>
		<!-- 用户 -->
		<div class="user">
			<div class="msg" @click="$router.push({path:'/audit'})">
				<el-badge :value="setNum.trade" :hidden="Number(setNum.trade)>0?false:true" class="item">
					<p>企业建会待审核</p>
				</el-badge>
			</div>
			<div class="msg" @click="$router.push({path:'/result',query:{status:'0'}})">
				<el-badge :value="setNum.elect" :hidden="Number(setNum.elect)>0?false:true" class="item">
					<p>选举结果待审核</p>
				</el-badge>
			</div>
			<div class="username">
				<el-dropdown trigger="click">
					{{setdata.username}}
					<span>
						<img :src="setdata.headimg?setdata.headimg:'/assets/img/tx.png'" />
					</span>
					<i class="icon iconfont icon-xiala"></i>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item @click="$router.push('/info')">个人资料</el-dropdown-item>
							<el-dropdown-item @click="signout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/user";

	export default {
		data() {
			return {
				crumbname: '正式工会列表',
				setdata: {},
				qyname: '',
				sjname: '',
				setNum: {}
			}
		},
		computed: {
			crumbname() {
				return this.$route.name
			},
			qyname() {
				return this.$route.query.name || this.$route.query.ername
			},
			sjname() {
				return this.$route.query.typename
			}
		},
		mounted() {
			this.getinfo()
			var that = this
			this.$EventBus.on('RefreshUser', function() {
				that.getinfo()
			})
			this.settime()
			this.getstatistics()
		},
		methods: {
			// 获取统计
			getstatistics() {
				this.$http.post(api.statistics)
					.then((res) => {
						this.setNum = res.data
					})
			},
			// 轮询
			settime() {
				let that = this
				this.timer = window.setInterval(() => {
					if (sessionStorage.getItem('token')) {
						that.getstatistics()
					}
				}, 10000)
			},
			// 菜单折叠
			isCollapse() {
				this.$EventBus.emit("IsCollapse");
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: sessionStorage.getItem('userid')
					})
					.then((res) => {
						console.log(res, "数据")
						this.setdata = res.data
					})
			},
			// 退出登录
			signout() {
				ElMessageBox.confirm(
						'是否确定退出登录?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						sessionStorage.removeItem('token')
						let getroute = []
						this.$store.dispatch('GETNAVLIST', getroute)
						this.$router.push('/login')
						ElMessage({
							type: 'success',
							message: '退出成功',
						})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>


<style lang="scss" scoped>
	::v-deep.el-dropdown-menu__item {
		font-size: 13px;
	}

	.head {
		display: flex;
		align-items: center;
		padding: 0 10px;
		width: 100%;
		font-size: 13px;
		height: 50px;
		background-color: #FFFFFF;

		.user {
			display: flex;
			align-items: center;

			.username {
				margin-left: 30px;
				cursor: pointer;


				img {
					width: 32px;
					height: 32px;
					margin: 0 10px;
					border-radius: 50%;
				}

				.icon-xiala {
					font-size: 14px;
					color: #999999;
				}
			}

			.msg {
				cursor: pointer;
				padding-right: 55px;

				i {
					font-size: 22px;
				}
			}
		}

		.route-nav {
			flex: 1;
			margin-left: 30px;

			.home {
				font-weight: bold;
				cursor: pointer;
			}

			.home:hover {
				color: #488bff;
			}

			.line {
				margin: 0 12px;
				font-weight: bold;
				display: inline-block;
			}
		}

		.icon-daohang {
			cursor: pointer;
			font-size: 22px;
			font-weight: bold;
		}
	}
</style>
